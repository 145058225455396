@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import '../../App.vars.scss';

header {
 
    background-color: $background-blue;

    &.call-out {

        background-color: $background-red;

        .call-out {

            display: flex;
            min-height: 12rem;
            align-items: center;
            
            .call-out-text {

                color: $font-heading-colour;

                p {

                    &.notice {

                        padding-top: 24px;

                        span {

                            background-color: $background-button-alt;
                            padding-top: 0.4em;
                            padding-bottom: 0.4em;
                            padding-left: 0.5em;
                            padding-right: 0.5em;
                            color: #ffffff;
                        }

                        font-size: 1.1em;
                        font-weight: 500;
                        margin-bottom: 0.75em;

                        &.solo {

                            margin-bottom: 0px;
                        }
                    }

                    margin: 0px;
                    padding-bottom: 24px;
                }
            }

            .button-container {

                text-align: right;
                align-items: center;
                display: flex;
                padding-bottom: 14px;

                button {

                    height: 59px;
                    background-color: $background-button-alt;
                    border: 1px solid $background-button-alt;
                    border-radius: 10px;
                    opacity: 1;
                    padding-left: 1.4rem;
                    padding-right: 1.4rem;
                    text-align: left;
                    font-family: $font-button-family;
                    font-weight: $font-button-weight;
                    font-size: $font-button-size;
                    line-height: $font-button-line-height;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    border: 0px;
                    width: auto;
                }
            }
        }
    }

    .container.container-xxxl.container-header {

        padding-bottom: 1em;

        #logo {

            margin-top: 40px;
        }

        #mobile-menu-trigger,
        #mobile-menu-close {

            margin-top: 2em;
            font-size: 1.4em;
            color: $font-heading-colour;
            float: right;
            margin-right: 0.2em;
            cursor: pointer;
            display: none;

            &.active {

                display: block;
            }
        }

        #mobile-menu {

            display: none;
            
            &.active {
                display: block;
            }

            .mobile-nav {

                display: block;
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #aaa;

                padding-left: 14px;

                &:first-of-type {

                    margin-top: 0.3em;
                }

                a, span {

                    padding-top: 0.5em;
                    padding-bottom: 0.5em;
                    color: $font-nav-colour;
                    display: block;
                    font-weight: $font-nav-weight;
                    color: $font-nav-colour;
                    text-decoration: none;
                }

                &:last-of-type {

                    border-bottom: none;

                    a, span {

                        padding-bottom: 0em;
                    }
                }
            }
        }

        .headerSelectClientToggle {

            margin-top: 30px;
            height: 48px;
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 1em;
            padding-top: 0.65em;
            background-color: #ffffff;
            color: $font-heading-colour;
            font-weight: $font-nav-weight;
            margin-bottom: 0px;
            border-radius: 10px;
            border: 1px solid $border-grey;
            background-color: inherit;

            span {

                max-width: 20em;
                display: block;
                height: 1.6em;
                overflow: hidden;
            }

            i {
                padding-left: 1em;
                padding-top: 0.2em;
            }
        }

        span.nav {

            display: block;
            padding-right: 5px;
            text-align: left;

            &:first-of-type {
                padding-right: 0px;
            }
            
            a {

                padding-top: 40px;
                display: block;
                font-weight: $font-nav-weight;
                color: $font-nav-colour;
                text-decoration: none;
                padding-left: 5px;
                padding-right: 5px;

                &.avatar {

                    text-align: left;
                    letter-spacing: 0px;
                }
            }

            button {

                letter-spacing: 0px;
                background-color: $background-button-alt;
                border: 0px;
                border-radius: 50%;
                font-weight: 500;
                width: 54px;
                height: 54px;
                margin-top: 25px;
                color: #FFFFFF;
                margin-left: 12px;
                margin-right: 0px;
            }

            &.avatar {

                position: relative;

                div {

                    display: none;
                    position: absolute;
                    min-width: 10em;
                    right: 0.1em;
                    top: 5em;
                    background-color: $background-button-alt;
                    border-radius: 10px 10px 10px 10px;
                    box-shadow: 2px 2px 5px #888;

                    &.active {

                        display: block;
                    }

                    a, span {

                        text-align: left;
                        color: white;
                        display: block;
                        padding-left: 24px;
                        padding-top: 24px;
                        font-weight: 400;
                        padding-bottom: 24px;
                        cursor: pointer;
                        border-bottom: 1px solid #ffffff;

                        &:last-of-type { 

                            border-bottom: none;
                        }
                    }
                }
            }
        }

        .button-small {
            position: absolute;
            top: 0em;
            right: 1em;
        }

    }
}


@include media-breakpoint-up(sm) {
 
    .mobile-select {

        margin-left: -10px;
    }

    header {

        .container.container-xxxl.container-header {

            #mobile-menu {

                margin-left: -10px;

                .mobile-nav {

                    &:first-of-type {

                        margin-top: -1em;
                    }

                    &:last-of-type {
                        margin-bottom: 0.75em;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {

    header {

        &.call-out {

            .call-out {

                .call-out-text {

                    p {

                        &.notice {
                            padding-top: 14px;
                        }
                    }
                }
            }
        }

        .container.container-xxxl.container-header {

            .left-column {

                .headerSelectClientToggle {

                    max-width: 240px;
                    margin-bottom: 1.5em;
                }
            }

            .justify-content-end {
                
                span.nav {
                
                    text-align: right;
                }
            }

            padding-bottom: 0px;
        }
    }
}


@include media-breakpoint-up(lg) {

    header {
        
        min-height: 105px;

        .container.container-xxxl.container-header {

           
            .headerSelectClientToggle {

                min-width: 240px;
            }

            .left-column {

                #logo {

                    margin-top: 29px;
                    width: 170px;
                    margin-left: 12px;
                }
            }

        }
    }
}

@include media-breakpoint-up(xl) {

    header {

        .container.container-xxxl.container-header {
 
            span.nav {

                a {

                    padding-left: 15px;
                    padding-right: 15px;
                }

                button {

                    margin-left: 12px;
                }
            }
        }
    }
}


@include media-breakpoint-up(xxl) {

    header {

        .container.container-xxxl.container-header {
            
            .left-column {

                flex: 0 0 315px;
            }
        }
    }
}

@media (min-width: 1920px) {
    
    header {

        &.call-out {

            .call-out {
    
                .call-out-text {
    
                    p {
    
                        padding-left: 34px;
                    }
                }
            }
        }

        .container.container-xxxl.container-header {
            
            max-width: 1920px;

            .left-column {

                flex: 0 0 340px;

                #logo {

                    width: 200px;
                    margin-left: 36px;
                }
            }

            .headerSelectClientToggle {

                margin-top: 25px;
                height: 55px;
            }
        }
    }
}
