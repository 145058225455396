@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import '../../App.vars.scss';

.badge {
    background-color: $background-yellow;
    border: 2px solid black;
    transform: rotate(-5deg);
    box-shadow: 3px 3px 3px #aaa;
    font-size: 1.2em;
    color: black;
}

.projects {

    margin-bottom: 2rem;
    cursor: pointer;

    .project {

        border-top: 1px solid $table-border-colour;
        padding-top: 5px;
        padding-bottom: 5px;
        position: relative;

        .entry {

            color: $font-heading-colour;
            font-size: $font-subheading-size;
            padding-left: 1.25rem;
            padding-top: 0.8rem;
            padding-bottom: 0.8rem;
            background-color: $background-grey;
            display: flex;
            min-height: 3.7em;

            span {
                flex: 1 0 100px;
            }
        }

        &.active {

            .entry {

                padding-left: 1rem;
                border-left: 0.25rem solid $font-nav-active;
                background-color: $background-blue;
            }
        }

        &:hover {
           
            .entry {

                background-color: $background-blue;
            }
        }

        &.active .entry button, &:hover .entry button {
            display: block;
        }
    }
}

button.concertina {

    width: 100%;
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    height: 55px;
    padding: 1em;
    padding-top: 0.65em;
    background-color: #ffffff;
    color: $font-heading-colour;
    font-weight: $font-nav-weight;
    margin-bottom: 0px;
    border-radius: 10px;
    border: 1px solid $background-grey;
    background-color: $background-grey;
    margin-bottom: 2em;

    i {

        padding-left: 1em;
        padding-top: 0.2em;
    }
}

@include media-breakpoint-up(lg) {

    .container-fluid {

        .container.container-xxxl.container-body {
        
            .left-column {

                .projects {

                    margin-right: 12px;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {

    .container-fluid {

        .container.container-xxxl.container-body {
        
            .left-column {

                button.concertina {

                    width: 96.5%;
                }
            }
        }
    }
}


@media (min-width: 1920px) {

    .container-fluid {

        .container.container-xxxl.container-body {
        
            .left-column {

                .projects {

                    margin-right: 24px;
                }

                button.concertina {

                    width: 93.5%;
                }
            }
        }
    }
}
