@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import '../../App.vars.scss';

footer {

    background-color: $background-grey;

    .container.container-xxxl.container-footer {

        padding-top: 65px;
        height: 260px;
        text-align: center;

        a {

            color: $font-link-colour;
            display: block;
            margin-top: 1rem;
        }
    }
}

@include media-breakpoint-up(sm) {

    footer {

        margin-top: auto; 

        .container.container-xxxl.container-footer {

            padding-left: 24px;
            text-align: left;

            a {
                display: inline;
                margin-top: 0px;
            }
        }
    }
}

@media (min-width: 1920px) {
    
    .container.container-xxxl.container-footer {

        max-width: 1920px;
    }
}
