@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import '../../App.vars.scss';

.container-fluid.yellow {

    background-color: $background-yellow;

    .container.container-xxxl.container-body {

        .text-list {
        
            font-family: Rubik,"Helvetica Neue",sans-serif;
            font-size: .9375rem;
            color: #1d2d91;
            list-style-type: none;
            padding: 0;
            margin: 0.8em 0 24px;
        }

        .toolkit {

            &:last-of-type {
                
                padding-bottom: 70px;
            }

            .tool {

                background-color: $background-modal-grey;
                border-radius: 10px;
                padding-left: 37px;
                padding-right: 35px;
                padding-top: 45px;
                box-shadow: 5px 5px 0px rgba(255, 217, 51, 1);
                min-height: 340px;
                margin-bottom: 35px;

                .lock {

                    color: $font-nav-active;
                    font-size: 1.4em;
                }

                h2 {

                    font-family: $font-subheading-family;
                    font-weight: $font-subheading-weight;
                    font-size: $font-button-size;
                    color: $font-heading-colour;
                }

                button {

                    background-color: $background-button-alt;
                    border: 1px solid $background-button-alt;
                    color: #ffffff;
                    border-radius: 10px;
                    padding-left: 23px;
                    padding-right: 23px;
                    height: 61px;

                    &.exists {
                        background-color: $background-green;
                        border: 1px solid $background-green;
                    }
                }

                label.sign {

                    background-color: $background-yellow;
                    border: 2px solid black;
                    transform: rotate(-5deg);
                    box-shadow: 3px 3px 3px #aaa;
                    color: black;
                    text-transform: uppercase;
                    padding-left: 23px;
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    padding-right: 23px;
                    height: 61px;
                }

                .images {

                    align-items: center;

                    img {

                        width: 100%;
                    }
                }

                p {
                    
                    color: $background-button;

                    &.hover {

                        display: none;
                    }
                }

                .no-template {

                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 40px;

                    &.images {

                        margin-bottom: 0px;
                    }


                    h2 {

                        padding-top: 0.3em;
                    }

                    button {

                        margin-top: 0px;
                    }
                }
               

                .template {

                    display: flex;
                    padding-top: 10px;
                    padding-bottom: 25px;

                    .select-wrapper {

                        flex-grow: 1;

                        .select__control {

                            display: flex;
                            height: 61px;
                            padding-left: 20px;
                            background-color: #ffffff;
                            color: $font-body-colour;
                            border-radius: 10px;
                            border: 0px;
                            margin-right: 18px;
        
                            .select__value-container {
        
                                padding: 0px;
        
                                .select__input-container {
        
                                    margin: 0px;
                                    padding: 0px;
                                }
                            }
                        }
                    }
                }

                &.wide {
                
                    padding-bottom: 1em;

                    p {

                        max-width: 85%;
                    }
                }
            }


            .locked:hover {

                p {
                    
                    &.hover {

                        display: block;
                    }

                    &.static {

                        display: none;
                    }
                }
            }
        }
    }
}


@include media-breakpoint-up(md) {

    .container-fluid.yellow {

        .container.container-xxxl.container-body {
    
            .toolkit {
    
                .tool {
    
                    margin-right: 24px;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {

    .container-fluid.yellow {

        .container.container-xxxl.container-body {
    
            .toolkit {
    
                .tool {
    
                    margin-right: 0px;

                    .images {
    
                        display: flex;
                        padding-bottom: 20px;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {

    .container-fluid.yellow {

        .container.container-xxxl.container-body {
    
            .toolkit {
    
                .tool {
    
                    .images {
    
                        padding-bottom: 20px;

                        img {
    
                            width: 310px;
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}
