$font-body-family: Rubik, "Helvetica Neue", sans-serif; 
$font-body-weight: 400; /* Regular */
$font-body-size: 1.25rem;
$font-body-line-height: 1.75rem;
$font-body-colour: #333333;

$font-heading-family: Pridi, "Helvetica Neue", sans-serif;
$font-heading-weight: 400; /* Regular */
$font-heading-size: 2.5rem;
$font-heading-line-height: 3rem;
$font-heading-colour: #1d2d91;
$font-subheading-family: Rubik, "Helvetica Neue", sans-serif; 
$font-subheading-weight: 700; /* Bold */
$font-subheading-colour: #777;
$font-subheading-size: 0.9375rem;
$font-subheading-line-height: 4.1875rem;

$font-nav-weight: 500; /* Medium */
$font-nav-colour: #2c4590;  
$font-nav-active: #ed3523;

$font-button-family: Pridi, "Helvetica Neue", sans-serif;
$font-button-weight: 400; /* Regular */
$font-button-size: 1.56265rem;
$font-button-size-secondary: 0.9375rem;
$font-button-line-height: 3rem;

$font-callout-size: 1.875rem;

$font-link-colour: #1d2d91;

$background-blue: #cae2e6;
$background-grey: #f4f4f4;
$background-orange: #ffe3bf;
$background-red: #f1d9e7;
$background-yellow: #ffed71;
$background-green: #89c0ba;
$background-button: #466682;
$background-button-alt: #1d2d91;
$background-button-secondary: #89c0ba;
$background-button-yellow: #ffed71;
$background-button-blue: #cae2e6;
$background-button-red: #f2d9e7;

$background-modal-grey: #f5f5f5;

$border-grey: #979797;

$table-border-colour: #cae2e6;

$form-input-colour: #919191;
$form-error-colour: #ed3523;