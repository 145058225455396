@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import './App.vars.scss';

html {

    font-size: 14px;

    body {
        font-family: $font-body-family;
        font-weight: $font-body-weight;
        font-size: $font-body-size;
        line-height: $font-body-line-height;
        color: $font-body-colour;

        #root {

            >.container-fluid {

                min-height: 100vh;
                display: flex; 
                flex-direction: column; 
                height: 100%;
                padding: 0px;

                &.fake-header {
                    min-height: 0px;
                    height: auto;
                }
            }    
        }
    }
}

.container-fluid {

    background-color: #ffffff;

    .container.container-xxxl.container-body {

        padding-bottom: 36px;

        padding-top: 24px;

        h1 {
            font-family: $font-heading-family;
            font-weight: $font-heading-weight;
            font-size: $font-heading-size;
            line-height: $font-heading-line-height;
            color: $font-heading-colour;
            padding-top: 0.2rem;
            opacity: 1;
            padding-bottom: 25px;

            &.list-title {

                padding-bottom: 0px;
                line-height: 1.9rem;
            }
        }

        h2 {

            &.list-sub-title {

                font-family: $font-heading-family;
                font-weight: $font-heading-weight;
                font-size: 1.5rem;
                line-height: 2rem;
                color: $font-subheading-colour;
                padding-bottom: 25px;
            }
        }

        button.add, button.edit, button.button {

            height: 59px;
            background: $background-button 0% 0% no-repeat padding-box;
            border-radius: 10px;
            opacity: 1;
            padding-right: 1.4rem;
            text-align: left;
            font-family: $font-button-family;
            font-weight: $font-button-weight;
            font-size: $font-button-size;
            line-height: $font-button-line-height;
            letter-spacing: 0px;
            color: #FFFFFF;
            border: 0px;
            margin-bottom: 3rem;
        
            span {

                display: none;
            }

            i {

                margin-left: 16px;
                margin-right: 8px;
            }
            
            &.icon {

                span {

                    display: none;
                }

                i {

                    margin-right: 0px;
                }
            }

            &.button {

                padding-left: 1.4rem;
            }

            &.yellow {

                color: $background-button;
                background-color: $background-button-yellow;
                border: 1px solid $background-button-yellow;
            }

            &.red {

                color: $background-button;
                background-color: $background-button-red;
                border: 1px solid $background-button-red;
            }

            &.blue {

                color: $background-button;
                background-color: $background-button-blue;
                border: 1px solid $background-button-blue;
            }

            &.mr {
                margin-right: 0.75em;
            }
        }

        .alert-danger.reload {

            display: flex;

            p {

                flex: 1 0 80px;
                font-size: 0.8em;
                margin-bottom: 0px;
            }

            i {
                flex: 0 0 30px;
                text-align: right;
            }

        }

        h4 {

            font-family: $font-subheading-family;
            font-weight: $font-subheading-weight;
            font-size: $font-subheading-size;
            color: $font-heading-colour;
            margin-top: -1.1rem;
            max-width: 210px;

            &.back {
                
                padding-top: 2.6rem;
                cursor: pointer;
                padding-bottom: 1.3rem;

                i {
                
                    padding-right: 1em;
                }
            }

            &.no-margin {

                margin-top: 0px;
            }
        }
    }

    &.red {
     
        background-color: $background-red;
    }

    &.blue {
     
        background-color: $background-blue;
    }

    &.grey {
     
        background-color: $background-grey;
    }
}

@include media-breakpoint-up(sm) {

    .container-fluid {

        .container.container-xxxl.container-body {

            padding-left: 0px;
            padding-right: 0px;

            button.add, button.edit, button.button {

                span {

                    display: inline;
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {

    .container-fluid {

        .container.container-xxxl.container-body {

            padding-top: 48px;
        }
    }
}

@include media-breakpoint-up(lg) {

    .container-fluid {

        .container.container-xxxl.container-body {

            padding-top: 74px;
        }
    }
}

@media (min-width: 1920px) {
    
    html {

        font-size: 16px;
    }

    .container-fluid {

        .container.container-xxxl.container-body {
        
            max-width: 1920px;

            .left-column {

                flex: 0 0 360px;
                padding-left: 2.2em;
            }

            .right-column {

                flex: 0 0 1260px;
            }

            button.edit {
                 
                &.icon {

                    span {

                        display: inline;
                    }

                    i {
                        
                        margin-right: 8px;
                    }
                }
            }
        }

        .Toastify__toast-body > div:last-child {

            font-size: 0.8em;
        }
    }
}

@import './scss/Table.scss';
@import './scss/Modal.scss';