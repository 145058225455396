@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import '../../App.vars.scss';

.container.container-xxxl.container-body {

    .call-to-action-loader {
        
        svg {

            margin-top: 40px;
            margin-left: 40px;
        }
    }

    .call-to-action {
        
        border-radius: 10px;
        cursor: pointer;
        padding-top: 54px;
        padding-left: 44px;
        padding-right: 44px;
        min-height: 376px;
        margin-top: 40px;
        background-color: $background-grey;

        &.small {
            
            min-height: 12em;
        }

        &.medium {
            
            min-height: 12em;

            h3 {

                max-width: 10em;
            }

        }

        &.orange-rocket {
            background-color: $background-orange;
        }

        &.red-computer {
            background-color: $background-red;
        }

        &.yellow-spacemen {
            background-color: $background-yellow;
        }

        h2 {

            font-family: $font-subheading-family;
            font-weight: $font-subheading-weight;
            font-size: $font-button-size;
            color: $font-heading-colour;
        }

        h3 {

            font-family: $font-subheading-family;
            font-weight: normal;
            font-size: $font-callout-size;
            color: $font-heading-colour;
            padding-bottom: 1rem;
        }
    }

    .examples {

        padding-top: 5em;
    }

    .left-column {

        .call-to-action-loader {
        
            svg {
    
                width: 70%;
            }
        }
    
        .call-to-action  {

            padding-left: 44px;
            padding-right: 44px;
            background-color: $background-grey;

            .last {

                font-size: 1.2em;
                padding-top: 0.4em;
            }

            a {

                border: 4px solid #ffffff;
                border-radius: 10px;
                padding-top: 26px;
                padding-bottom: 26px;
                display: block;
                width: 100%;
                text-align: center;
                margin-bottom: 1rem;

                span {

                    background-color: $background-green;
                    color: #ffffff;
                    width: 46px;
                    height: 46px;
                    border-radius: 50%;
                    display: block;
                    margin: auto;
                    padding-left: 0.2em;
                    padding-top: 0.4em;
                    font-size: $font-button-size;
                }
            }
        }
    }
}

@include media-breakpoint-up(xxl) {

    .container.container-xxxl.container-body {

        .call-to-action {
            
            background-repeat: no-repeat;
            background-size: 30%;
            background-position-y: 50%;
            background-position-x: right;

            p {
                max-width: 260px;
            }

            &.orange-rocket {
                
                background-image: url(/assets/images/orange-rocket.png);

                &.small {

                    background-size: 25%;
                }
            }

            &.red-computer {
                
                background-image: url(/assets/images/red-computer.png);
                background-size: 40%;

                &.small {

                    background-size: 30%;
                }

                &.medium {

                    background-size: 28%;
                }
            }

            &.grey-computer {
                
                background-image: url(/assets/images/grey-flag.png);
                background-size: 40%;

                &.small {

                    background-size: 30%;
                }

                &.medium {

                    background-size: 28%;
                }
            }

            &.yellow-spacemen {
                
                background-image: url(/assets/images/yellow-spacemen.png);
                background-size: 40%;

                &.medium {

                    background-size: 30%;
                }

                &.small {

                    background-size: 27%;
                }
            }
        }
    }
}



@media (min-width: 1920px) {
    
    .container-fluid {

        .container.container-xxxl.container-body {
        
            .left-column {

                .call-to-action, .alert {
                    
                    margin-right: 48px;
                }
            }
        }
    }
}
