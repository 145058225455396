@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import '../../App.vars.scss';


.container-fluid .container.container-xxxl.container-body {
    
    .reporting .call-to-action {
        
        cursor: default;
        min-height: 300px;

        h2 {

            padding-bottom: 25px;
        }

        p {
            padding-bottom: 25px;
            max-width: 100%;
        }

        .metric {

            display: flex;
            width: 100%;
            justify-content: flex-end;
            padding-bottom: 10px;

            &.heading {

                padding-bottom: 35px;
            }

            span {

                flex: 1 0 20px;
                
                &.number {

                    flex: 0 0 7.5em;
                    font-weight: bold;
                    font-size: 1.1em;
                    text-align: right;
                }
            }
        }
    }
}
