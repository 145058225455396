.modal {

    &.select-modal {

        .modal-dialog {

            margin: 0px;

            .modal-content {

                box-shadow: 2px 2px 5px 5px rgba(200, 200, 200, 0.2);
                border: 1px solid #eee;

                .modal-header {

                    color: $font-link-colour;
                    border-bottom: 0px;
                    padding-bottom: 20px;
                    padding-top: 1em;

                    .h4 {

                        font-size: $font-body-size;
                        padding-left: 0.3em;
                    }

                    i {

                        cursor: pointer;
                        margin-left: 0.8em;
                    }

                    button {
                        background-color: $background-button-secondary;
                        height: 38px;
                        border-radius: 10px;
                        opacity: 1;
                        padding-left: 0.4rem;
                        padding-right: 0.7rem;
                        text-align: left;
                        font-weight: bold;
                        font-size: $font-button-size-secondary;
                        letter-spacing: 0px;
                        color: #FFFFFF;
                        border: 0px;
                        width: auto;
                    }
                }

                .modal-body {

                    padding-top: 0px;
                    padding-left: 1em;
                
                    input[type=text] {

                        display: block;
                        height: 55px;
                        width: 100%;
                        padding-left: 20px;
                        background-color: #ffffff;
                        color: $font-body-colour;
                        margin-bottom: 0.5em;
                        border-radius: 10px;
                        border: 1px solid $border-grey;
                    }

                    .selectable-row {

                        color: $font-link-colour;
                        cursor: pointer;
                        padding-top: 1.5rem;
                        padding-bottom: 1.5rem;
                    }
                }
            }
        }
    }

    &.form-modal {
    
        .modal-dialog {
        
            display: flex;

            .modal-content {

                box-shadow: 2px 2px 5px 5px rgba(200, 200, 200, 0.2);
                border: 1px solid #eee;
                padding-left: 30px;
                background-repeat: no-repeat;
                background-position: top right;
                
                .modal-header {

                    font-size: $font-button-size;
                    padding-top: 0px;
                    border-bottom: 0px;

                    .modal-title {

                        color: $font-heading-colour;
                        padding-top: 30px;
                        font-weight: $font-subheading-weight;
                    }

                    button {

                        margin-top: 30px;
                        margin-right: 30px;
                        color: $font-heading-colour;
                        background-color: inherit;
                        border: 0px;
                        padding: 0px;
                    }
                }
        
                .modal-body {

                    .select {

                        padding-right: 20px;

                        .research {

                            background-color: #ffffff;
                            border-radius: 10px;
                            padding: 20px;
                            margin-bottom: 24px;

                            &.clickable {
                                
                                cursor: pointer;
                            }

                            .title {

                                display: flex;
                                justify-content: space-between;
                                min-height: 60px;

                                button {

                                    height: 59px;
                                    background-color: $background-button;
                                    border: 1px solid $background-button;
                                    border-radius: 10px;
                                    opacity: 1;
                                    padding-left: 1.4rem;
                                    padding-right: 1.4rem;
                                    text-align: left;
                                    font-family: $font-button-family;
                                    font-weight: $font-button-weight;
                                    font-size: $font-button-size;
                                    line-height: $font-button-line-height;
                                    letter-spacing: 0px;
                                    color: #FFFFFF;
                                    border: 0px;
                                    width: auto;
                                
                                    &.enquire {

                                        background-color: $background-button-alt;
                                        border: 1px solid $background-button;

                                    }

                                    i {
                                        margin-left: 16px;
                                        margin-right: 8px;
                                    }
                                }
                            }

                            h3 {

                                padding-top: 0.7em;
                            }

                            p {

                                padding-top: 14px;
                                font-size: 0.8em;
                            }

                            span {
                                    
                                display: block;

                                background-repeat: no-repeat;
                                background-position: center left;
                                padding-left: 20px;
                                padding-top: 2px;
                                font-size: 0.8em;

                                &.available { 

                                    background-image: url(/assets/images/research/available.png);
                                }

                                &.coming-soon { 

                                    background-image: url(/assets/images/research/coming-soon.png);
                                }
                            }

                            .footer {

                                display: flex;
                                justify-content: space-between;

                                .enquiry {

                                    color: $font-heading-colour;
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    form {

                        padding-bottom: 65px;

                        fieldset {

                            margin-top: 20px;
                            max-width: 640px;
                            padding-right: 30px;
                            display: flex; 
                            flex-direction: column; 
            
                            label {

                                display: block;
                                font-size: $font-subheading-size;
                                padding-bottom: 5px;
                            }

                            .radio-group {

                                display: flex;
                                min-height: 2.2em;
                                margin-bottom: 1.1em;

                                span {

                                    align-items: center;
                                    display: flex;
                                
                                    input[type=radio] {

                                        margin-right: 0.7em;
                                    }
                                }

                                label {

                                    align-items: center;
                                    display: flex;
                                    font-size: 1em;
                                    margin-right: 2em;
                                    padding: 0px;
                                }
                            }

                            input[type=text] {

                                display: block;
                                height: 55px;
                                width: 100%;
                                padding-left: 20px;
                                background-color: #ffffff;
                                color: $font-body-colour;
                                margin-bottom: 1em;
                                border-radius: 10px;
                                padding-right: 20px;
                                border: none;
                            }

                            textarea {

                                display: block;
                                height: 255px;
                                width: 100%;
                                padding-left: 20px;
                                padding-top: 20px;
                                background-color: #ffffff;
                                color: $font-body-colour;
                                margin-bottom: 1em;
                                border-radius: 10px;
                                padding-right: 20px;
                                border: none;
                            }

                            .select__control {

                                display: flex;
                                height: 55px;
                                width: 100%;
                                padding-left: 20px;
                                background-color: #ffffff;
                                color: $font-body-colour;
                                margin-bottom: 1em;
                                border-radius: 10px;
                                border: 0px;

                                .select__value-container {

                                    padding: 0px;

                                    .select__input-container {

                                        margin: 0px;
                                        padding: 0px;
                                    }
                                }
                            }

                            p {
                                font-size: 0.8em;
                            }

                            .error {
                                color: $form-error-colour;
                            }

                            .server-error {
                                color: $form-error-colour;
                                margin-bottom: 2em;
                            }
                        }

                        .actions {

                            margin-top: auto;
                            display: flex;

                            button {

                                height: 59px;
                                background: $background-button-alt;
                                border-radius: 10px;
                                opacity: 1;
                                padding-left: 1.4rem;
                                padding-right: 1.4rem;
                                text-align: left;
                                font-family: $font-button-family;
                                font-weight: $font-button-weight;
                                font-size: $font-button-size;
                                line-height: $font-button-line-height;
                                letter-spacing: 0px;
                                color: #FFFFFF;
                                border: 0px;
                                width: auto;
                            
                                i {
                                    margin-left: 16px;
                                    margin-right: 8px;
                                }
                            }

                            .server-success {

                                padding-left: 3em;
                                display: flex;
                                align-items: center;
                            }
                        }
                    }
                }
            }
        }

        &.red-modal,
        &.grey-modal,
        &.yellow-modal {
                    
            .modal-dialog {
            
                .modal-content {

                    background-color: $background-modal-grey;
                    border: 1px solid $background-modal-grey;

                    .modal-header {

                        .modal-title {

                            color: $font-body-colour;

                            span {
                                
                                display: block;
                                font-size: 1.2em;
                                font-weight: normal;
                            }
                        }
                    }

                    .modal-body {

                        form {

                            input {

                                border: 1px solid $background-modal-grey;
                            }

                            .actions {

                                margin-top: auto;

                                button {

                                    background-color: $background-button;
                                    border: 1px solid $background-button;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.blue-modal {
                    
            .modal-content {

                background-color: $background-blue;
                border: 1px solid $background-blue;
            }
        }

        &.yellow-modal {
                    
            .modal-dialog {
            
                .modal-content {

                    background-color: $background-yellow;
                    border: 1px solid $background-yellow;
                }
            }
        }

        &.red-modal {
                    
            .modal-dialog {
            
                .modal-content {

                    background-color: $background-red;
                    border: 1px solid $background-red;
                }
            }
        }

        &.complex-modal {

            .modal-dialog {

                .modal-content {
                    
                    .modal-body {
                        
                        form {
                            
                            fieldset {

                                max-width: 100%;

                                input[type=text] {

                                    font-size: 0.8em;
                                    height: 45px;
                                }

                                textarea {

                                    font-size: 0.8em;
                                    display: block;
                                    padding-top: 20px;
                                    height: 130px;
                                    width: 100%;
                                    padding-left: 20px;
                                    background-color: #ffffff;
                                    color: $font-body-colour;
                                    border: none;
                                    margin-bottom: 1em;
                                    border-radius: 10px;
                                    padding-right: 20px;
                                }

                                .intl-tel-input {

                                    display: block;
                                    width: 100%;
                                    background-color: #ffffff;
                                    color: $font-body-colour;
                                    margin-bottom: 0.8em;
                                    border-radius: 10px;

                                    input[type=tel] {
                            
                                        height: 45px;
                                        border-radius: 10px;
                                        padding-right: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.confirm-modal {
    
        .modal-dialog {
        
            display: flex;

            .modal-content {

                box-shadow: 2px 2px 5px 5px rgba(200, 200, 200, 0.2);
                border: 1px solid #eee;
                padding-left: 30px;
                background-repeat: no-repeat;
                background-position: top right;
                background-color: $background-modal-grey;
                border: 1px solid $background-modal-grey;

                .modal-header {

                    font-size: $font-button-size;
                    padding-top: 0px;
                    border-bottom: 0px;

                    .modal-title {

                        color: $font-heading-colour;
                        padding-top: 30px;
                        font-weight: $font-subheading-weight;
                    }

                    button {

                        margin-top: 30px;
                        margin-right: 30px;
                        color: $font-heading-colour;
                        background-color: inherit;
                        border: 0px;
                        padding: 0px;
                    }
                }
        
                .modal-body {

                    form {

                        padding-bottom: 65px;

                        fieldset {

                            margin-top: 20px;
                            max-width: 640px;
                            padding-right: 30px;
                            display: flex; 
                            flex-direction: column; 
            
                            p {

                                padding-bottom: 0.5em;
                                padding-right: 2em;

                                &.action-object {
                                    
                                    padding-bottom: 1.5em;
                                    font-weight: bold;
                                }
                            }

                            .server-error {
                                color: $form-error-colour;
                                margin-bottom: 2em;
                            }
                        }

                        .actions {

                            margin-top: auto;
                            display: flex;

                            button {

                                height: 59px;
                                background: $background-button-alt;
                                border-radius: 10px;
                                opacity: 1;
                                padding-left: 1.4rem;
                                padding-right: 1.4rem;
                                text-align: left;
                                font-family: $font-button-family;
                                font-weight: $font-button-weight;
                                font-size: $font-button-size;
                                line-height: $font-button-line-height;
                                letter-spacing: 0px;
                                color: #FFFFFF;
                                border: 0px;
                                width: auto;
                            
                                &.danger {

                                    background-color: $font-nav-active;
                                }

                                i {
                                    margin-left: 16px;
                                    margin-right: 8px;
                                }
                            }

                            .server-success {

                                padding-left: 3em;
                                display: flex;
                                align-items: center;
                            }
                        }
                    }
                }
            }
        }

        // &.red-modal,
        // &.grey-modal,
        // &.yellow-modal {
                    
        //     .modal-dialog {
            
        //         .modal-content {

        //             background-color: $background-modal-grey;
        //             border: 1px solid $background-modal-grey;

        //             .modal-header {

        //                 .modal-title {

        //                     color: $font-body-colour;

        //                     span {
                                
        //                         display: block;
        //                         font-size: 1.2em;
        //                         font-weight: normal;
        //                     }
        //                 }
        //             }

        //             .modal-body {

        //                 form {

        //                     input {

        //                         border: 1px solid $background-modal-grey;
        //                     }

        //                     .actions {

        //                         margin-top: auto;

        //                         button {

        //                             background-color: $background-button;
        //                             border: 1px solid $background-button;
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }

        // &.blue-modal {
                    
        //     .modal-content {

        //         background-color: $background-blue;
        //         border: 1px solid $background-blue;
        //     }
        // }

        // &.yellow-modal {
                    
        //     .modal-dialog {
            
        //         .modal-content {

        //             background-color: $background-yellow;
        //             border: 1px solid $background-yellow;
        //         }
        //     }
        // }

        // &.red-modal {
                    
        //     .modal-dialog {
            
        //         .modal-content {

        //             background-color: $background-red;
        //             border: 1px solid $background-red;
        //         }
        //     }
        // }

        // &.complex-modal {

        //     .modal-dialog {

        //         .modal-content {
                    
        //             .modal-body {
                        
        //                 form {
                            
        //                     fieldset {

        //                         max-width: 100%;

        //                         input[type=text] {

        //                             font-size: 0.8em;
        //                             height: 45px;
        //                         }

        //                         textarea {

        //                             font-size: 0.8em;
        //                             display: block;
        //                             padding-top: 20px;
        //                             height: 130px;
        //                             width: 100%;
        //                             padding-left: 20px;
        //                             background-color: #ffffff;
        //                             color: $font-body-colour;
        //                             border: none;
        //                             margin-bottom: 1em;
        //                             border-radius: 10px;
        //                             padding-right: 20px;
        //                         }

        //                         .intl-tel-input {

        //                             display: block;
        //                             width: 100%;
        //                             background-color: #ffffff;
        //                             color: $font-body-colour;
        //                             margin-bottom: 0.8em;
        //                             border-radius: 10px;

        //                             input[type=tel] {
                            
        //                                 height: 45px;
        //                                 border-radius: 10px;
        //                                 padding-right: 20px;
        //                             }
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
    }

    &.menu-modal {

        position: absolute;

        .modal-dialog {

            margin: 1.75rem auto;
            border-radius: 10px;
            overflow: hidden;
            width: 20rem;

            .modal-content {
                box-shadow: 2px 2px 5px 5px rgba(200, 200, 200, 0.2);
                border: 1px solid #eee;
                background-color: $background-grey;
                border: 1px solid $background-grey;

                .modal-header {

                    color: $font-link-colour;
                    border-bottom: 0px;
                    padding-bottom: 0px;
                    padding-left: 0.9rem;
                    padding-right: 0px;
                    padding-top: 3px;
                    border-bottom: 1px solid #ffffff;

                    .h4 {

                        font-size: $font-body-size;
                        padding-left: 0.3em;
                        justify-content: flex-end;
                        display: flex;
                        width: 100%;
                        color: $font-body-colour;
                        align-items: center;
                        font-weight: normal;

                        span {

                            flex: 1 0 14px;

                            &.action {

                                flex: 0 0 44px;
                                border-left: 1px solid #ffffff;
                                text-align: center;
                                display: flex;
                                align-items: center;
                                min-height: 3.2em;

                                i {
                                    text-align: center;
                                    margin: auto;
                                    cursor: pointer;
                                }

                            }
                        }
                    }
                }

                .modal-body {

                    padding-top: 0px;
                    padding-left: 1em;
                    background-color: $background-yellow;
                
                    .selectable-row {

                        color: $font-link-colour;
                        cursor: pointer;
                        border-bottom: 1px solid #ffffff;
                        padding-top: 1.5rem;
                        color: $font-body-colour;
                        padding-bottom: 1.5rem;

                        &:last-of-type {
                            border-bottom: none;
                            padding-bottom: 0.5rem;
                        }
                    }
                }
            }
        }
    }
}


.modal-backdrop {

    background-color: white;
}

@include media-breakpoint-down(sm) {

    .modal {

        &.form-modal {

            .modal-dialog {

                .modal-content {
                    
                    .modal-body {

                        .select {

                            .research {

                                .title {

                                    img {

                                        max-width: 7.5em;
                                        margin-top: 0.3em;

                                        &.short {
                                            margin-top: 0.9em;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {

    .modal {

        &.select-modal {

            .modal-dialog {

                margin: 6.5rem 0px 0px 1.5rem;
            }
        }

        &.form-modal {

            .modal-dialog {

                max-width: 80%;
            }
        }
    }

}

@include media-breakpoint-up(lg) {

    .modal {

        &.select-modal {

            .modal-dialog {

                margin: 6.0rem 0px 0px 1.5rem;
                max-height: 50%;
            }
        }

        &.form-modal {

            .modal-dialog {
                    
                .modal-content {

                    .modal-body {

                        form {

                            padding-right: 0px;
                            width: 50%;
                            
                            &.wide {
                                width: 100%;
                            }
                    
                            fieldset {
                                min-height: 390px;
                            }

                            .error {
                                text-align: right;
                            }
            
                        }

                        .select {

                            .research { 

                                p {
                                
                                    min-height: 160px;

                                    &.narrow {
                                        width: 65%;
                                    }
                                }

                                &.custom {

                                    p {
                                    
                                        min-height: 165px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.blue-modal {
                                
                .modal-content {

                    background-image: url(/assets/images/modals/modal-background-blue.png);
                    background-size: 44%;
                }
            }

            &.grey-modal {
                        
                .modal-content {

                    background-image: url(/assets/images/modals/modal-background-grey.png);
                    background-size: 40%;
                }
            }

            &.red-modal {
                        
                .modal-content {

                    background-image: url(/assets/images/modals/modal-background-yellow.png);
                    background-size: 40%;
                }
            }

            &.yellow-modal {
                        
                .modal-content {

                    background-image: url(/assets/images/modals/modal-background-yellow.png);
                    background-size: 42%;
                }

                &.select-group-modal {

                    .modal-content {

                        background-image: none;
                    }
                }
            }

            &.complex-modal {

                .modal-dialog {
        
                    .modal-content {
                    
                        background-image: none !important;

                        .modal-body {

                            form {

                                width: 100%;

                                .select {

                                    .research { 
        
                                        p {
                                        
                                            min-height: 160px;
        
                                            &.narrow {
                                                width: 65%;
                                            }
                                        }
        
                                        &.custom {
        
                                            p {
                                            
                                                min-height: 235px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {

    .modal {

        &.select-modal {

            .modal-dialog {

                margin: 1rem 0px 0px 0px;
            }
        }

        &.form-modal {

            .modal-dialog {
            
                max-width: 1160px;
        
                .modal-content {

                    padding-left: 65px;
        
                    .modal-header {
        
                        .modal-title {

                            padding-top: 65px;
                        }

                        button {

                            margin-right: 20px;
                            margin-top: 0px;
                        }
                    }

                    .modal-body {

                        form {
        
                            width: 100%;

                            fieldset {
        
                                min-height: 470px;
                            }

                            &.wide {

                                fieldset {

                                    height: 675px;
                                }
                            }
                            
                        }

                        .select {

                            .research { 

                                p {

                                    min-height: 110px;
                                }

                                p.narrow {

                                    min-height: 20px;
                                }

                                &.custom {

                                    p {

                                        min-height: 135px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.complex-modal {

                .modal-dialog {
        
                    .modal-content {
                    
                        .modal-body {

                            form {

                                p.note {

                                    max-width: 80%;
                                }
                            }
                        }
                    }
                }
            }

            &.select-group-modal {

                .modal-dialog {
            
                    .modal-content {
    
                        padding-left: 30px;
                        background-image: none !important;
                    }
                }
            }

            &.blue-modal {
                        
                .modal-content {
                    
                    background-size: 37%;
                }
            }
        }
    }
}

@media (min-width: 1920px) {

    .modal {

        &.form-modal {

            .modal-dialog {
            
                .modal-content {

                    .modal-body {

                        .select {
        
                            .research { 


                                h3 {

                                    padding-top: 0.4em;
                                }

                                p {

                                    min-height: 152px;
                                }

                                &.custom {

                                    p {

                                        min-height: 181px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.complex-modal {

                .modal-dialog {
            
                    .modal-content {
    
                        .modal-body {
    
                            form {

                                fieldset {

                                    .radio-group {

                                        min-height: 2.0em;
                                        margin-bottom: 1.0em;
                                    }

                                    textarea {

                                        height: 137px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


