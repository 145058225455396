#brandStrategyDesigned {
  .brandStrategyDesigned {
    display: none;
  }
}

.brandStrategyDesigned {

  .page {

    display: block;
    margin: 0 auto;
    letter-spacing: 0.2px;
    page-break-after: always;
    width: 842px;
    height: 595px;
    background-color: #ffffff;
    padding-top: 23px;


    .inner {

      width: 796px;
      height: 549px;
      margin: auto;
      position: relative;

      .competitors-list {
        display: flex;
        justify-content: flex-start;
        -moz-column-count: 3;
        -moz-column-gap: 30px;
        column-gap: 30px;
        padding-right: 15px;
        flex-wrap: wrap;
      }

      .y-axis-label {

        width: 600px;
        text-align: center;
      }

      .matrix-wrapper {

        padding: 12px;
        position: relative;
        width: 600px;

        .matrix {

          position: relative;
          min-height: 330px;
          z-index: 2;

          .y-axis {

            top: 0px;
            left: 0px;
            width: 50%;
            position: absolute;
            height: 100%;
            z-index: 1;
            border-right: 1px solid #000000;
          }
        
          .x-axis {

            left: 0px;
            top: 50%;
            width: 100%;
            position: absolute;
            height: 50%;
            z-index: 1;
            text-align: right;
            border-top: 1px solid #000000;
            font-family: 'Rubik', 'Rubik-Light', sans-serif;

            span {

              line-height: 2em;
            }
          }

          .matrix-cell {

            width: 40px;
            font-size: 0.9em;
            height: 40px;
            /* margin-top: -2em;
            margin-left: -2em; */
            z-index: 2;
            position: absolute;
            text-align: center;
            line-height: 11px;
            display: flex;
            justify-content: center;
            align-items: center;
    
            &.left-margin-minus {
              margin-left: -40px;
            }
            
            &.top-margin-minus {
              margin-top: -40px;
            }
          
            span {

              font-size: 11px;
              font-family: 'Pridi', 'Pridi-light', serif;
              color: #000;
              font-weight: 300;
              width: 100%;
            }

            img {

              width: auto;
              margin: auto;
              max-width: 40px;
              max-height: 40px;
            }
          }
        }
      }

      &.content-page-inner {

        padding-left: 50px;

        .heading {

          justify-content: space-between;
          align-items: start;
          padding-top: 37px;
          margin-bottom: 40px;
        }

        &.canvas {

          padding-left: 0px;

          .heading {

            padding-top: 7px;
            margin-bottom: 0px;
          }

          h6 {

            font-family: Pridi,"Pridi-light",serif;
            font-size: 8px;
            margin-bottom: 12px;
            color: #76787b;

            &.bottom {

              position: absolute;
              bottom: 0px;
            }
          }

          p {

            font-size: 8px;
            margin: 4px 0 !important;
          }
        }

        h3 {

          font-family: 'Pridi', 'Pridi-light', serif;
          font-size: 22px;
          color: #134393;
          font-weight: 300;
          line-height: 1.3;
        }

        .column {

          width: 338px;

          &.hero-image {

            text-align: center;

            img {

              max-width: 200px;
            }
          }
        }
  
        p {

          font-family: 'Rubik', 'Rubik-Regular', sans-serif;
          font-weight: 400;
          font-size: 12px;
          color: #1d1d1b;
          line-height: 1.48;
          margin-bottom: 6px;
          letter-spacing: 0.09px;

          span {

            font-weight: 700;
            font-family: 'Rubik', 'Rubik-Bold', sans-serif;
            color: #1d1d1b;
          }

          &.break {

            padding-bottom: 3em;
          }

          &.pitch {

            max-width: 555px;
            font-size: 1.25em;
            line-height: 1.8;
          }
        }


        .bar-left,
        .bar-right {

            float: left;
            width: 159px;
        }

        .inner-bar {

          background: #d6e2e6;
          padding: 15px;
          height: 502px;
          overflow: hidden;
        }

        .bar-right {
      
          .inner-bar {

            height: 295px;

            &.top {

              height: 247px;
            }

            &.bottom {

              margin-top: 3px; 
              height: 251px;
            }
          }
        }

        .centre-box {

          float: left;
          width: 465px;
          margin: 0 5px;

          .middle-box {

            float: left;
            margin: 0 5px;
            position: relative;

            .round {

              z-index:9;
              background: #fff;
              border-radius: 50%;
              height: 94px;
              width: 104px;
              text-align: center;
              padding: 0;
              position: absolute;
              top: 50%;
              left: 0;
              right: 0;
              transform: translate(63%, -49%);
              -webkit-transform: translate(63%, -49%);
              -moz-transform: translate(63%, -49%);
              -o-transform: translate(63%, -49%);

              h6 {

                margin-top: 13px;
              }
            }


            .centre-inner {

              margin-top: 5px;
              height: 108px;
              background-color: #fded76;
              position: relative;
              padding: 7px;

              &.centre-bottom {

                margin-top: 6px;
              }

              .centre-content {

                width: 213px;
              }
            }

          }

          .centre-inner {

            padding-left: 15px;
            padding-right: 15px;
            padding-top: 15px;
            height: 133px;
            background-color: #fad3e2;

            &.bottom {

              background-color: #d7dd98;
              height: 137px;
              margin-top: 5px;
              display: block;
              width: 465px;
              position: absolute;
              bottom: 5px;
            }

            .centre-content {

              display: block;
              width: 100%;
              float: left;

              .centre-text {

                margin-top: 0;
                margin-left: 0;
                width: 100%;
                margin-right: 0;
                float: left;

                ul {

                  margin: 0px;
                  padding: 0px;
                  list-style-type: none;
                  font-size: 8px;
                  font-weight: bold;
                }

                .left, .right, &.left, &.right {

                  width: 50%;
                  float: left;
                }

                &.right {

                  text-align: right;
                }
              }
            }
          }

          .bar {

            float: left;

            .inner-bar {

              height: 262px;
              background: #b3dde0;
              margin-top: 5px;
              height: 222px;
              width: 114px;
              padding-left: 15px;
              padding-right: 6px;
            
              p {

                margin: 8px 0;
              }
            }
          }
        }


        footer {

          width: 100%;
          font-family: 'Rubik', 'Rubik-Light', sans-serif;
          font-size: 10px;
          padding: 0 100px 0 0px;
          position: absolute;
          background-color: inherit;
          bottom: 0;

          span {

            border-top: 1px solid #134393;
            display: block;
            padding: 7px 0 0px 0;
            color: #000;
          }
        }
      }
    
      .competitor {

        padding: 4px 0 4px;
        border-bottom: 1px solid #d3dce6;
        width: 21%;
        min-height: 74px;
        
        img {

          height: 35px;
          width: auto;
          max-width: 250px;
          margin-bottom: 6px;
        }
        
        p {

          font-weight: 700;
          font-family: 'Rubik', 'Rubik-Bold', sans-serif;
          color: #1d1d1b;
          font-size: 12px;
          line-height: 1.48;
        }
      }
     
      h1 {

        font-family: 'Pridi', 'Pridi-light', serif;
        font-weight: 300;
        font-size: 60px;
        color: #134393;
        line-height: 60px;
        margin-left: 47px;
        padding-top: 48px;
        margin-bottom: 19px;
        max-width: 577px;
        letter-spacing: -1.3px;
      }

      h2 {

        font-family: 'Pridi', 'Pridi-light', serif;
        font-size: 22px;
        color: #134393;
        font-weight: 300;
        line-height: 1.3;
        margin-top: 8px;
        margin-left: 50px;
        max-width: 577px;
      }


      h3 {
        font-size: 32px;
        color: #000000;
        font-weight: 300;
        line-height: 1.3;
      }
  
      .agency-logo-top {
        
        position: absolute;
        top: 20px;
        right: 20px;
        max-width: 78px;
        max-height: 56px;
        z-index: 9;
      }
    }

    .sub-heading {
      font-size: 12px;
      margin-bottom: 9px;
      color: #1d1d1b;
      font-weight: 700;
      font-family: 'Rubik', 'Rubik-Bold', sans-serif;
    }

    .brand-strengths {
      
      p {

        max-width: 335px;
        margin-bottom: 0px;
      }

      img {

        width: 483px;
        margin: 0;
        margin-left: 96px;
      }
    }

    .archetype {

      padding-right: 14px;
  
      img {
        display: block;
        width: 455px;
        margin-bottom: 15px;
      }
  
      h4 {
        font-family: 'Pridi', 'Pridi-light', serif;
        font-weight: 300;
        font-size: 1.3em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        margin-bottom: 0px;
      }
  
      p {
        display: block;
        color: #1d1d1b;
        font-size: 12px;
        font-family: 'Rubik', 'Rubik-Regular', sans-serif;
        font-weight: 400;
      
        &.approach {
          padding-bottom: 0.5em;
          margin-bottom: 1em;
        }
      }
    }

    .essence-container {

      position: relative;
      font-family: 'Rubik', 'Rubik-Light', sans-serif;
      color: #000;
      width: 80%;
      margin: 0 auto;
    
      .value, .essence {

        border: 1px solid #134393;
        position: absolute;
        height: 7em;
        width: 35%;
        font-family: 'Rubik', 'Rubik-Light', sans-serif;
      }
      
      .essence {

        text-align: center;
        top: 6em;
        left: 55%;
        z-index: 2;
        background-color: #ffffff;
        margin-left: -25%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
  
      .value {

        margin-bottom: 2em;
        text-align: center;
        right: 5%;
        font-family: 'Rubik', 'Rubik-Light', sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;

        &.left {

          text-align: center;
          left: 0px;
        }

        &.bottom {
          top: 12em;
        }
      }
    }

    &.start-page, &.end-page {

      .inner {

        .blue-background-image {

          left: 0px;
          top: 0px;
          position: absolute;
          z-index: 1;
        }
  
        h1 {

          font-size: 75px;
          position: relative;
          z-index: 5;
          color: #e83c56;
          line-height: 1.2;
        }

        h2 {

          position: relative;
          z-index: 5;
          font-size: 36px;
          color: #ffffff;
        }
      }

      footer {

        font-family: 'Rubik', 'Rubik-Light', sans-serif;
        font-size: 10px;
        height: 62px;
        padding: 14px 45px 0px 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #000000;
        position: absolute;
        left: 0;
        bottom: 0px;
        width: 100%;
        background-color: #ffffff;
      }
    }

    &.end-page {

      img {

        position: absolute;
      
        &.agency {

          position: static;
          max-width: 78px
        }

        &.logo {

          left: 50px;
          bottom: 100px;
          z-index: 100;
        }
      }
    }

    &.coloured-page {

      .inner {

        img {

          max-height: 480px;
          position: absolute;
          right: 0px;
          top: 18px;
        }

        p {

          font-family: 'Pridi', 'Pridi-light', serif;
          font-size: 18px;
          color: #134393;
          font-weight: 300;
          line-height: 1.26;
          margin-top: 8px;
          margin-left: 50px;
          max-width: 620px;
        }

        ul {

          padding: 0px;
          margin-top: 8px;
          margin-left: 50px;
          max-width: 590px;
          list-style-type: none;

          li {
            
            font-family: 'Pridi', 'Pridi-light', serif;
            font-size: 18px;
            color: #134393;
            margin-top: 10px;
            font-weight: 300;
            line-height: 1.26;
          }
        }

        footer {

          width: 100%;
          font-family: 'Rubik', 'Rubik-Light', sans-serif;
          font-size: 10px;
          padding: 0 50px 0 50px;
          position: absolute;
          background-color: inherit;
          bottom: 0;

          span {

            border-top: 1px solid #134393;
            display: block;
            padding: 7px 0 10px 0;
            color: #000;
          }
        }
      }

      &.yellow-page {

        .inner {

          background-color: #ffed71;
        }
      }

      &.blue-page {
         
        .inner {

          background-color: #c1e3e6;
        }
      }

      &.red-page {

        .inner {

          background-color: #fadae9;
        }
      }
    }
  }
}