@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import '../../App.vars.scss';

.container-fluid {

    .container.container-xxxl.container-body {
        
        .left-column {

            .standalone {

                margin-top: 12px;
                margin-bottom: 36px;
            }
        }
    }
}

@include media-breakpoint-up(lg) {

    .container-fluid {

        .container.container-xxxl.container-body {
        
            .left-column {

                .standalone {

                    margin-right: 12px;
                }
            }
        }
    }
}

@media (min-width: 1920px) {

    .container-fluid {

        .container.container-xxxl.container-body {
        
            .left-column {

                .standalone {

                    margin-right: 24px;
                }
            }
        }
    }
}