.table-container {

    &.secondary {
        padding-top: 1em;
    }

    table {

        width: 100%;
        margin-bottom: 3.5em;

        thead {

            tr {

                th {

                    color: $font-heading-colour;
                    font-weight: normal;
                    padding-bottom: 1em;

                    &.actions {

                        text-align: right;
                    }

                    &.label {

                        width: 12em;
                        padding-left: 2em;
                        padding-right: 2em;
                    }
                }
            }
        }

        tbody {

            tr {

                td {

                    color: $form-input-colour;
                    border-top: 1px solid $table-border-colour;
                    padding-top: 0.6em;
                    padding-bottom: 0.6em;

                    &.name {
                        color: $font-heading-colour;
                    }

                    &.job-number {
                        width: 8em;
                    }

                    &.dark {
                        color: $font-body-colour;
                    }

                    &.fixed {
                        max-width: 12em;
                    }

                    span {

                        color: $font-body-colour;

                        &.name {

                            display: block;
                        }

                        &.sub-item {

                            display: block;
                            color: $form-input-colour;
                        }
                    }

                    &.label {

                        width: 12em;
                        padding-left: 2em;
                        padding-right: 2em;

                        span {

                            height: 61px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: $background-grey;
                            border-radius: 10px;
                        }
                    }

                    &.date {

                        width: 12em;
                    }


                    em {

                        font-style: normal;
                        display: block;
                    }

                    a {

                        display: block;
                        color: $font-link-colour;
                    }

                    &.actions {

                        text-align: right;
                        position: relative;

                        button {

                            height: 61px; 
                            padding-left: 1em;
                            padding-right: 1.5em;
                            background-color: $background-red;
                            border: 1px solid $background-red;
                            border-radius: 10px 0px 0px 10px;
                            
                            &.single {
                                
                                border-radius: 10px;
                            }

                            &.open {

                                background-color: $background-button-yellow;
                                border: 1px solid $background-button-yellow;

                                &.red {

                                    background-color: $background-button-red;
                                    border: 1px solid $background-button-red;
                                }
                            }

                            &.toggle {

                                padding-left: 0.8em;
                                padding-right: 0.8em;
                                background-color: $background-grey;
                                border: 1px solid $background-grey;
                                border-left: 2px solid #ffffff;
                                border-radius: 10px 10px 10px 10px;
                            }

                            &.locked {

                                cursor: default;
                            }
                        }

                        .context-menu {

                            position: absolute;
                            left: -12.5em;
                            top: 0.6em;
                            width: 16em;
                            z-index: 100;
                            display: none;
                            border-radius: 10px;
                            box-shadow: 2px 2px 5px 5px rgba(200, 200, 200, 0.4);
                            border: 1px solid #eee;
                            background-color: $background-grey;
                            border: 1px solid $background-grey;

                            &.red {
                                
                                left: -13.3em;
                            }

                            &.active {
                                
                                display: block;
                            }

                            .header {

                                color: $font-link-colour;
                                border-bottom: 0px;
                                padding-bottom: 0px;
                                padding-left: 0.9rem;
                                padding-right: 0px;
                                padding-top: 3px;
                                border-bottom: 1px solid #ffffff;
                                justify-content: flex-end;
                                display: flex;
                                width: 100%;
                                color: $font-body-colour;
                                text-align: left;
                                align-items: center;
                                font-weight: normal;

                                span {

                                    flex: 1 0 14px;

                                    &.action {

                                        flex: 0 0 44px;
                                        border-left: 1px solid #ffffff;
                                        text-align: center;
                                        display: flex;
                                        align-items: center;
                                        min-height: 3.2em;

                                        i {
                                            text-align: center;
                                            margin: auto;
                                            cursor: pointer;
                                        }

                                    }
                                }
                            }

                            .content {

                                padding-top: 0px;
                                padding-left: 1em;
                                background-color: $background-yellow;
                                text-align: left;
                            
                                .selectable-row {
            
                                    color: $font-link-colour;
                                    cursor: pointer;
                                    border-bottom: 1px solid #ffffff;
                                    padding-top: 1.5rem;
                                    color: $font-body-colour;
                                    padding-bottom: 1.5rem;
            
                                    &:last-of-type {
                                        border-bottom: none;
                                    }
                                }
                            }

                            &.red {

                                .content {

                                    background-color: $background-red;
                                }
                            }
                        }
                    }

                    &.single {

                        width: 115px;

                        button {

                            &.open {

                                padding-left: 1.5em;
                                border-radius: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}




@include media-breakpoint-up(sm) {
    
    .table-container {

        table {

            tbody {

                tr {

                    td {

                        &.actions {
        
                            width: 175px;

                            button {

                                &.toggle {

                                    border-radius: 0px 10px 10px 0px;

                                }

                                
                                i {

                                    margin-right: 8px;
                                }
                            }

                            .context-menu {

                                left: -6.1em;

                                &.red {
                                    
                                    left: -6.1em;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (min-width: 1920px) {

    .table-container {

        table {

            tbody {

                tr {

                    td {

                        &.actions {

                            width: 200px;
                        }
                    }
                }
            }
        }
    }
}