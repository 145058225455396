@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import '../../App.vars.scss';


.container-fluid {

    .container.container-xxxl.container-body {

        p.introduction {

            margin-bottom: 3em;
        }

        .alert-warning {

            display: flex;
            align-items: center;

            span {

                flex: 1 0 240px;
                padding-right: 2em;
            }

            button.button {

                flex: 0 0 auto;
                margin-bottom: 0px;
                background-color: $background-button-alt;
            }

        }

        .competitor {

            border-radius: 10px;
            background-color: #ddd;
            margin-bottom: 24px;
            min-height: 200px;
            background-color: $background-blue;
            padding: 2em;

            &.first {

                background-image: url(/assets/images/yellow-spacemen.png);
                background-size: 30%;
                background-repeat: no-repeat;
                background-position: right center;
                background-color: $background-yellow;
            }
        }

        button.standalone-save {

            margin-top: 3em;
        }

    }
}



// @include media-breakpoint-up(lg) {

//     .container-fluid {

//         .container.container-xxxl.container-body {
        
//             .left-column {

//                 .projects {

//                     margin-right: 12px;
//                 }
//             }
//         }
//     }
// }

// @include media-breakpoint-up(lg) {

//     .container-fluid {

//         .container.container-xxxl.container-body {
        
//             .left-column {

//                 button.concertina {

//                     width: 96.5%;
//                 }
//             }
//         }
//     }
// }


// @media (min-width: 1920px) {

//     .container-fluid {

//         .container.container-xxxl.container-body {
        
//             .left-column {

//                 .projects {

//                     margin-right: 24px;
//                 }

//                 button.concertina {

//                     width: 93.5%;
//                 }
//             }
//         }
//     }
// }
